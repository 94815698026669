import { isSameMonth } from 'date-fns';
import { getDateDiffInDays } from '@/lib/helpers/getDateDiff';
import {
  DeadlineStatus,
  FUTURE_MONTHS_TO_RENDER,
} from '@/components/platform/Payment/PaymentsPage/constants';
import {
  CompletedPaymentActionPayload,
  CompletedTodoActionPayload,
  JobSearchActionType,
  MonthTodo,
  PaymentFeeData, ProofOfRevenueDescriptionData,
  TodoStatus,
} from '@/components/platform/Payment/PaymentsPage/typedefs';
import { UserCourseMonthPaymentFragment } from '@/components/platform/Payment/graphql/generated/userCourseMonthPayment.fragment.generated';
import { ProofOfRevenueBaseFragment } from '@/controllers/proofOfRevenue/graphql/generated/ProofOfRevenueBase.fragment.generated';
import { UserJobsList } from '@/controllers/userJob/userJob.typedefs';
import { ProofOfRevenuePeriodData } from '@/controllers/proofOfRevenue/typedefs';

interface NextTodoPayload {
  paymentDeadline?: Date;
  nextMonthPaidOffDate?: Date;
  makePaymentAvailableDate?: Date;
  paymentFeeData?: PaymentFeeData;
  proofOfRevenuePeriodData?: ProofOfRevenuePeriodData | null;
}

interface ConstructorProps {
  nextTodoPayload: NextTodoPayload;
  agreementMonths: number;
  completedPayments: UserCourseMonthPaymentFragment[];
  proofsOfRevenue: ProofOfRevenueBaseFragment[];
  userJobs: UserJobsList;
}

interface InitProps {
  proofsOfRevenue: ProofOfRevenueBaseFragment[];
  completedPayments: UserCourseMonthPaymentFragment[];
  agreementMonths: number;
  userJobs: UserJobsList;
}

interface GetActionDataProps {
  deadline?: Date | null;
  feeData?: PaymentFeeData;
  availableAt?: Date;
  descriptionData?: ProofOfRevenueDescriptionData;
}

interface InitTodosLengthProps {
  paymentsLeft: number;
  hasCurrentJob: boolean;
  isCurrentPaymentCompleted: boolean | null;
  prevJobsList: UserJobsList['prev'];
}

export class PaymentTodosGenerator {
  private readonly nextTodoPayload: NextTodoPayload = {};

  private sortedCompletedPayments: CompletedPaymentActionPayload[] = [];

  private sortedProofsOfRevenue: CompletedTodoActionPayload[] = [];

  private todosLength = 0;

  private deadlineYear = new Date().getFullYear();

  private deadlineMonth = new Date().getMonth();

  private monthsUntilNow = 0;

  private shouldGenerateFindJobTodo = false;

  constructor({
    nextTodoPayload,
    agreementMonths,
    proofsOfRevenue,
    completedPayments,
    userJobs,
  }: ConstructorProps) {
    this.nextTodoPayload = nextTodoPayload;

    this.init({
      proofsOfRevenue: proofsOfRevenue ?? [],
      completedPayments: completedPayments ?? [],
      agreementMonths,
      userJobs,
    });
  }

  private init({
    proofsOfRevenue,
    completedPayments,
    agreementMonths,
    userJobs,
  }: InitProps) {
    const now = Date.now();

    const paymentExpectedDate = new Date(
      this.nextTodoPayload.nextMonthPaidOffDate ?? now,
    );
    const proofOfRevenueData = this.nextTodoPayload.proofOfRevenuePeriodData;

    const proofOfRevenueExpectedDate = proofOfRevenueData?.collectionStartDate
      ? new Date(proofOfRevenueData.collectionStartDate)
      : null;

    // if proof of revenue is the closest action - it means the payment for current month is already done/skipped
    const isCurrentPaymentCompleted = proofOfRevenueExpectedDate && (
      paymentExpectedDate > proofOfRevenueExpectedDate
    );

    const closestActionDate = isCurrentPaymentCompleted
      ? proofOfRevenueExpectedDate
      : paymentExpectedDate;

    this.deadlineYear = closestActionDate.getUTCFullYear();
    this.deadlineMonth = closestActionDate.getUTCMonth();

    const yearDifference = new Date().getUTCFullYear() - this.deadlineYear;
    const monthDifference = new Date().getUTCMonth() - this.deadlineMonth;

    this.monthsUntilNow = yearDifference * 12 + monthDifference + 1;

    const acceptedPayments = completedPayments.filter(
      ({ skipped }) => !skipped,
    );

    const {
      current: currentJobsList,
      prev: prevJobsList,
    } = userJobs;

    const hasCurrentJob = Boolean(currentJobsList.length);
    const paymentsLeft = agreementMonths - acceptedPayments.length;

    this.initTodosLength({
      paymentsLeft,
      hasCurrentJob,
      prevJobsList,
      isCurrentPaymentCompleted,
    });

    this.initCompletedActionsPayload(
      proofsOfRevenue,
      completedPayments,
    );
  }

  private initTodosLength({
    paymentsLeft,
    hasCurrentJob,
    prevJobsList,
    isCurrentPaymentCompleted,
  }: InitTodosLengthProps): void {
    const maxActionsLeft = isCurrentPaymentCompleted // Since we haven't yet completed declaration, the todoItem is still active
      ? paymentsLeft + 1 // So we add +1 for not decreasing number of todos rendered
      : paymentsLeft;

    const maxTodosToRender = this.monthsUntilNow + FUTURE_MONTHS_TO_RENDER;

    const projectedTodosLength = maxActionsLeft > maxTodosToRender
      ? maxTodosToRender
      : maxActionsLeft;

    if (hasCurrentJob) {
      this.todosLength = projectedTodosLength;

      return;
    }

    const latestPrevJob = prevJobsList.sort(
      (current, next) => (
        current.lastDay > next.lastDay
          ? -1
          : 1
      ),
    )[0];

    if (!latestPrevJob) {
      this.todosLength = projectedTodosLength;

      return;
    }

    const latestPrevJobEndDate = new Date(latestPrevJob.lastDay);

    const yearDifference = (
      new Date(latestPrevJobEndDate).getUTCFullYear() - this.deadlineYear
    );
    const monthDifference = (
      new Date(latestPrevJobEndDate).getUTCMonth() - this.deadlineMonth
    );

    const monthsUntilLeftJob = yearDifference * 12 + monthDifference + 1;

    if (monthsUntilLeftJob < projectedTodosLength) {
      this.shouldGenerateFindJobTodo = true;
      this.todosLength = monthsUntilLeftJob;

      return;
    }

    this.todosLength = projectedTodosLength;
  }

  generateTodos(): MonthTodo[] {
    const currentTodoDate = new Date(this.deadlineYear, this.deadlineMonth);

    return [
      ...this.generateCompletedTodo(currentTodoDate),
      ...this.generateCurrentTodo(currentTodoDate),
      ...this.generateFutureTodos(),
      ...this.generateJobSearchTodo(),
    ];
  }

  private generateJobSearchTodo(): MonthTodo[] {
    if (!this.shouldGenerateFindJobTodo) {
      return [];
    }

    return [{
      date: new Date(),
      status: TodoStatus.Unemployed,
      payment: null,
      proofOfRevenue: null,
      jobSearchActions: [
        JobSearchActionType.ContactSupport,
        JobSearchActionType.FindJob,
      ],
    }];
  }

  private generateCompletedTodo(currentTodoDate: Date): MonthTodo[] {
    const [prevPayments, prevProofsOfRevenue] = [
      this.sortedCompletedPayments.filter(
        ({ monthTimeStamp }) => !isSameMonth(monthTimeStamp, currentTodoDate),
      ),
      this.sortedProofsOfRevenue.filter(
        ({ monthTimeStamp }) => !isSameMonth(monthTimeStamp, currentTodoDate),
      ),
    ];

    const latestPayment = prevPayments[0];
    const latestProofOfRevenue = prevProofsOfRevenue[0];

    const isOnlyPaymentAvailable = Boolean(
      latestPayment && !latestProofOfRevenue,
    );
    const areActionsInDifferentMonths = Boolean(
      latestPayment && latestProofOfRevenue
        && !isSameMonth(
          latestPayment.monthTimeStamp,
          latestProofOfRevenue.monthTimeStamp,
        ),
    );

    const shouldShowPaymentOnly = Boolean(
      isOnlyPaymentAvailable || areActionsInDifferentMonths,
    );

    const completedTodoDate = latestPayment?.monthTimeStamp;

    if (!completedTodoDate) {
      return [];
    }

    const COMPLETED_TODO_DATA = {
      date: completedTodoDate,
      status: TodoStatus.Completed,
    };

    const COMPLETED_TODO_ACTION_DATA = {
      deadlineStatus: DeadlineStatus.Completed,
      daysDiff: 0,
    };

    if (shouldShowPaymentOnly) {
      return [{
        ...COMPLETED_TODO_DATA,
        payment: COMPLETED_TODO_ACTION_DATA,
        proofOfRevenue: null,
      }];
    }

    return [{
      ...COMPLETED_TODO_DATA,
      payment: COMPLETED_TODO_ACTION_DATA,
      proofOfRevenue: COMPLETED_TODO_ACTION_DATA,
    }];
  }

  // generate based on real data if possible
  private generateCurrentTodo(currentTodoDate: Date): MonthTodo[] {
    const {
      paymentDeadline,
      paymentFeeData,
      nextMonthPaidOffDate: paymentMonthTimestamp,
      makePaymentAvailableDate,
      proofOfRevenuePeriodData,
    } = this.nextTodoPayload;

    const {
      collectionStartDate,
      deadlineDate: proofOfRevenueDeadline,
    } = proofOfRevenuePeriodData ?? {};

    if (!paymentMonthTimestamp && !collectionStartDate) {
      return [];
    }

    const TODO_DATA = {
      date: currentTodoDate,
      status: TodoStatus.InProgress,
    };

    const isOnlyPaymentAvailable = (
      paymentMonthTimestamp && !collectionStartDate
    );
    const areActionsInDifferentMonths = Boolean(
      paymentMonthTimestamp && collectionStartDate
      && !isSameMonth(paymentMonthTimestamp, collectionStartDate),
    );

    const shouldProcessSeparately = (
      isOnlyPaymentAvailable || areActionsInDifferentMonths
    );

    const floatPercent = paymentFeeData?.percentageFeeAmount ?? 0;
    const latestPaymentAmount = this.sortedCompletedPayments[0]?.amount ?? 0;

    const paymentFeeDataPayload = paymentFeeData && {
      ...paymentFeeData,
      percentageFeeAmount: floatPercent * latestPaymentAmount,
    };

    const descriptionData = {
      periodName: proofOfRevenuePeriodData?.periodName ?? '',
      periodNumber: proofOfRevenuePeriodData?.periodNumber ?? '',
      periodYear: proofOfRevenuePeriodData?.periodYear ?? 0,
    };

    if (shouldProcessSeparately) {
      const isPaymentCloser = (
        (collectionStartDate as Date) > (paymentMonthTimestamp as Date)
      );

      const isOnlyPaymentLeftToBeDone = (
        isOnlyPaymentAvailable || isPaymentCloser
      );

      const payment = isOnlyPaymentLeftToBeDone
        ? this.getActionData({
          deadline: paymentDeadline,
          feeData: paymentFeeDataPayload,
          availableAt: makePaymentAvailableDate,
        })
        : this.findCompletedActionDeadlineData(
          new Date(collectionStartDate as Date),
          this.sortedCompletedPayments,
        );

      const proofOfRevenue = isOnlyPaymentLeftToBeDone
        ? this.findCompletedActionDeadlineData(
          new Date(paymentMonthTimestamp as Date),
          this.sortedProofsOfRevenue,
        )
        : this.getActionData({
          deadline: proofOfRevenueDeadline,
          availableAt: collectionStartDate,
          descriptionData,
        });

      return [Object.assign(TODO_DATA, { payment, proofOfRevenue })];
    }

    return [Object.assign(TODO_DATA, {
      payment: this.getActionData({
        deadline: paymentDeadline,
        feeData: paymentFeeDataPayload,
        availableAt: makePaymentAvailableDate,
      }),
      proofOfRevenue: this.getActionData({
        deadline: proofOfRevenueDeadline,
        availableAt: collectionStartDate,
        descriptionData,
      }),
    })];
  }

  private generateFutureTodos(): MonthTodo[] {
    return Array.from(
      { length: this.todosLength - 1 }, // -1 because we already generated first item
      (_, i) => {
        const date = new Date(this.deadlineYear, this.deadlineMonth + 1 + i); // +1 because current item is already generated

        return this.generateMockTodo(i, date);
      },
    );
  }

  private findCompletedActionDeadlineData(
    soughtDate: Date,
    entities: CompletedTodoActionPayload[],
  ) {
    const soughtMonth = soughtDate.getMonth();
    const soughtYear = soughtDate.getFullYear();

    const currentMonthEntity = entities.find(
      (entity) => (
        new Date(entity.monthTimeStamp).getMonth() === soughtMonth
        && new Date(entity.monthTimeStamp).getFullYear() === soughtYear
      ),
    );

    return currentMonthEntity
      ? { deadlineStatus: DeadlineStatus.Completed, daysDiff: 0 }
      : null;
  }

  private generateMockTodo(
    monthIndex: number,
    date: Date,
  ) {
    const mockActionData = this.getActionData();

    return {
      date,
      payment: mockActionData,
      proofOfRevenue: mockActionData,
      status: monthIndex + 1 === this.monthsUntilNow
        ? TodoStatus.NotAvailable
        : TodoStatus.PrevTodoRequired,
    };
  }

  private getActionData(props?: GetActionDataProps) {
    const {
      deadline,
      feeData,
      availableAt,
      descriptionData,
    } = props ?? {};

    if (!deadline) {
      return null;
    }

    const daysDiff = getDateDiffInDays(new Date(), new Date(deadline));

    const deadlineStatus = daysDiff > 0
      ? DeadlineStatus.InProgress
      : DeadlineStatus.Overdue;

    return {
      deadlineStatus,
      daysDiff,
      availableAt,
      feeData,
      descriptionData,
    };
  }

  private initCompletedActionsPayload(
    proofsOfRevenue: ProofOfRevenueBaseFragment[],
    completedPayments: UserCourseMonthPaymentFragment[],
  ) {
    const proofsOfRevenuePayload = proofsOfRevenue.map(
      (proofOfRevenue) => ({
        completedAt: new Date(proofOfRevenue.createdAt),
        monthTimeStamp: new Date(
          proofOfRevenue.proofOfRevenuePeriodData.collectionStartDate,
        ),
      }),
    );

    const paymentsPayload = completedPayments.map((payment) => ({
      completedAt: new Date(payment.createdAt),
      monthTimeStamp: new Date(payment.monthPaidOffDate),
      amount: payment?.amount,
    }));

    this.sortedProofsOfRevenue = (
      this.sortCompletedActions(proofsOfRevenuePayload)
    );

    this.sortedCompletedPayments = (
      this.sortCompletedActions(paymentsPayload)
    );
  }

  private sortCompletedActions(
    completedActions: CompletedTodoActionPayload[],
  ) {
    return completedActions.sort(
      (action, nextAction) => (
        action.completedAt > nextAction.completedAt
          ? -1
          : 1),
    );
  }
}
