import React, { DetailedHTMLProps, FC, TableHTMLAttributes } from 'react';
import { cn } from '@/lib/classNames';
import styles from './TableHead.module.scss';

type TableHeadElementProps = DetailedHTMLProps<
  TableHTMLAttributes<HTMLTableSectionElement>,
  HTMLTableSectionElement
>;

export type TableHeadProps = TableHeadElementProps;

export const TableHead: FC<TableHeadProps> = (props) => {
  const { children, className, ...rest } = props;
  const classes = cn(
    styles.tableHead,
    className,
  );

  return (
    <thead
      data-qa="table-head"
      {...rest}
      className={classes}
    >
      {children}
    </thead>
  );
};
