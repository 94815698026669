import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';

export const usePaymentsProofsOfRevenueTableHeaders = () => {
  const { t } = useTranslation([I18N_CODES.payment]);

  return {
    date: {
      title: t(`${I18N_CODES.payment}:proof_of_revenue_files_upload_date`),
    },
    period: {
      title: t(`${I18N_CODES.payment}:proof_of_revenue_files_period`),
    },
    files: {
      title: t(`${I18N_CODES.payment}:proof_of_revenue_files`),
    },
    status: {
      title: t(`${I18N_CODES.payment}:proof_of_revenue_files_status`),
    },
  };
};
