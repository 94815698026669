import React, { FC, useMemo } from 'react';
import { cn } from '@/lib/classNames';
import i18Next, { useTranslation } from '@/middleware/i18n';
import { useCanManageCalendarEvents } from '@/components/platform/Schedule/hooks/useCanManageCalendarEvents';
import { SidebarItem } from '@/components/platform/SidebarItem';
import { IconCalendar } from '@/components/ui/icons/IconCalendar';
import { I18N_CODES } from '@/lib/constants/general';
import { ROUTES } from '@/controllers/router/router.contants';
import styles from '@/components/platform/Sidebar/Sidebar.module.scss';

export const CalendarSidebarIcon: FC = () => {
  const { t } = useTranslation([I18N_CODES.platformSidebar]);
  const router = i18Next.useRouter();
  const canManageCalendarEvents = useCanManageCalendarEvents();

  const redirectHref = useMemo(() => (
    canManageCalendarEvents
      ? ROUTES.user.scheduleGroup
      : ROUTES.user.schedule
  ), [canManageCalendarEvents]);

  const isActive = (
    router.pathname.includes(ROUTES.user.schedule)
      || router.pathname.includes(ROUTES.user.scheduleGroup)
  );

  return (
    <li className={styles.navItem}>
      <SidebarItem
        className={cn({
          [styles.activeSidebarItem]: isActive,
        })}
        icon={<IconCalendar className={styles.sidebarIcon} />}
        text={t(`${I18N_CODES.platformSidebar}:sidebar.schedule_link`)}
        href={redirectHref}
        dataQa='calendar-link'
      />
    </li>
  );
};
