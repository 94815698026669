import React, { FC } from 'react';
import { Alert, AlertMode } from '@/components/ui/Alert';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';

interface Props {
  dueDateDay: number;
}

export const OverduePaymentAlert: FC<Props> = ({ dueDateDay }) => {
  const { t } = useTranslation([I18N_CODES.payment]);

  return (
    <Alert mode={AlertMode.Warning}>
      <p>
        {t(`${I18N_CODES.payment}:actions.payment_overdue_alert.text`, {
          due_date: dueDateDay,
          fee_start_date: dueDateDay + 1,
        })}
      </p>
    </Alert>
  );
};
