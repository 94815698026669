import {
  AuthUserWithProofOfRevenueQueryResult,
  useAuthUserWithProofOfRevenueQuery,
} from '@/controllers/user/graphql/generated/authUserWithProofOfRevenue.query.generated';
import {
  AuthUserWithProofOfRevenueFragment,
} from '@/controllers/user/graphql/generated/AuthUserWithProofOfRevenue.fragment.generated';

type UseAuthUserHookOutput = [
    AuthUserWithProofOfRevenueFragment | null,
  AuthUserWithProofOfRevenueQueryResult,
];

export const useAuthUserWithProofOfRevenue = (): UseAuthUserHookOutput => {
  const queryResult = useAuthUserWithProofOfRevenueQuery();

  const content = queryResult.data?.authUser || null;

  return [content, queryResult];
};
