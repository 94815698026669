import React, { FC } from 'react';
import { Link, useTranslation } from '@/middleware/i18n';
import { ROUTES } from '@/controllers/router/router.contants';
import { Tabs } from '@/components/platform/Tabs';
import { HISTORY_TABS_DATA, HistoryTab } from '@/components/platform/Payment/PaymentsPage/constants';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import { I18N_CODES } from '@/lib/constants/general';
import { useDefaultDomain } from '@/controllers/subDomain/subDomain.hooks/useDefaultDomain';

export const HistoryPageTabs: FC = () => {
  const { t } = useTranslation([I18N_CODES.payment]);

  const { historyTab } = useRouterQuery<{ historyTab: HistoryTab }>();
  const isDefaultSubdomain = useDefaultDomain();

  const supportedTabsData = isDefaultSubdomain
    ? HISTORY_TABS_DATA
    : HISTORY_TABS_DATA.filter((tab) => tab.id !== HistoryTab.Declarations);

  const tabsData = supportedTabsData.map((tabData) => ({
    ...tabData,
    title: t(`${I18N_CODES.payment}:${tabData.title_code}`),
  }));

  return (
    <Tabs
      activeTab={historyTab}
      tabsData={tabsData}
      renderTab={({ id, title, className }) => (
        <Link
          href={ROUTES.payments.history(id)}
          passHref
          key={title}
        >
          <a className={className}>
            {title}
          </a>
        </Link>
      )}
    />
  );
};
