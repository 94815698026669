import React, { FC } from 'react';
import { cn } from '@/lib';
import { Seos } from '@/components/common/Seos';
import { Sidebar } from '@/components/platform/Sidebar';
import { Header } from '@/components/platform/Header';
import { FlashMessageContainer } from '@/components/ui/flashMessage/FlashMessageContainer';
import { LayoutProps } from '@/components/layout/LayoutProps';
import {
  useAuthUserRuLanguageHandler,
} from '@/controllers/language/useAuthUserRuLanguageHandler';
import { UserProfile } from '@/components/platform/UserProfile';
import { UserProfileContextProvider } from '@/components/platform/UserProfile/UserProfileContext';
import { useShouldRenderMiniBanner } from '@/components/platform/Header/hooks/useShouldRenderMiniBanner';
import { ManagerUserTimeZone } from '@/components/common/ManagerUserTimezone';
import { NotificationIndications } from '@/components/common/NotificationsIndications';
import { GlobalSearchProvider } from '@/components/platform/GlobalSearch/components/GlobalSearchProvider';
import { GlobalSearchModal } from '@/components/platform/GlobalSearch/components/GlobalSearchModal';
import { PlatformThemeProvider } from '@/components/common/ThemeContext';
import styles from './PlatformLayout.module.scss';

type Props = LayoutProps;

export const PlatformLayout: FC<Props> = React.memo<Props>(
  (props) => {
    const { children, layoutOptions } = props;
    const {
      hideHeaderOnMobileScreen,
      whiteBackground,
      hasBackButton,
      seo,
    } = layoutOptions;

    useAuthUserRuLanguageHandler();

    const shouldApplyStyleWithBanner = useShouldRenderMiniBanner();

    return (
      <PlatformThemeProvider>
        <GlobalSearchProvider>
          <Seos seo={seo} />
          <ManagerUserTimeZone />
          <NotificationIndications />
          <GlobalSearchModal />

          <UserProfileContextProvider>
            <div
              className={cn(styles.pageWrapper, {
                [styles.white]: whiteBackground,
              })}
            >
              <FlashMessageContainer />

              <Header
                hideOnMobileScreen={hideHeaderOnMobileScreen}
                hasBackButton={hasBackButton}
              />

              <Sidebar />

              <div
                className={cn(styles.pageContent, {
                  [styles.fillHeaderSpace]: hideHeaderOnMobileScreen,
                  [styles.withMobileAppMiniBanner]: shouldApplyStyleWithBanner,
                })}
              >
                {children}

                <UserProfile />
              </div>
            </div>
          </UserProfileContextProvider>
        </GlobalSearchProvider>
      </PlatformThemeProvider>
    );
  },
);
