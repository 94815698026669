import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconList: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.66892 10.6757C3.88378 10.6757 3.25 11.3095 3.25 12.0946C3.25 12.8797 3.88378 13.5135 4.66892 13.5135C5.45405 13.5135 6.08784 12.8797 6.08784 12.0946C6.08784 11.3095 5.45405 10.6757 4.66892 10.6757ZM4.66892 5C3.88378 5 3.25 5.63378 3.25 6.41892C3.25 7.20405 3.88378 7.83784 4.66892 7.83784C5.45405 7.83784 6.08784 7.20405 6.08784 6.41892C6.08784 5.63378 5.45405 5 4.66892 5ZM4.66892 16.3514C3.88378 16.3514 3.25 16.9946 3.25 17.7703C3.25 18.5459 3.89324 19.1892 4.66892 19.1892C5.44459 19.1892 6.08784 18.5459 6.08784 17.7703C6.08784 16.9946 5.45405 16.3514 4.66892 16.3514ZM7.50676 18.7162H20.75V16.8243H7.50676V18.7162ZM7.50676 13.0405H20.75V11.1486H7.50676V13.0405ZM7.50676 5.47297V7.36486H20.75V5.47297H7.50676Z" fill="currentColor" />
  </BaseIcon>
);
