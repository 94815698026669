import React, { FC } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { HorizontalPositionMode, ToolTip, ToolTipWidthMode } from '@/components/ui/ToolTip';
import styles from '@/components/platform/Payment/TransactionStatusBadge/TransactionStatusBadge.module.scss';
import { IconQuestionRound } from '@/components/ui/icons/IconQuestionRound';
import { useTranslation } from '@/middleware/i18n';
import { Tag } from '@/components/ui/Tag';
import { TagColor } from '@/components/ui/Tag/Tag.typedefs';
import { BadgeType } from '@/components/platform/Payment/TransactionStatusBadge/typography';

interface Props {
  badgeType: BadgeType;
  errorMessage?: string | null;
}

const TAG_COLOR_BY_STATUS: Record<
  BadgeType,
  TagColor
> = {
  [BadgeType.New]: TagColor.Magenta,
  [BadgeType.Accepted]: TagColor.Success,
  [BadgeType.OnReview]: TagColor.Default,
  [BadgeType.Under_3dSecure]: TagColor.Info,
  [BadgeType.Declined]: TagColor.Danger,
  [BadgeType.Failed]: TagColor.Danger,
  [BadgeType.Skipped]: TagColor.Default,
  [BadgeType.Refunded]: TagColor.Default,
};

export const TransactionStatusBadge: FC<Props> = ({
  badgeType,
  errorMessage,
}) => {
  const { t } = useTranslation([I18N_CODES.payment, I18N_CODES.common]);

  return (
    <div className="flex-container align-middle">
      <Tag
        text={t(`${I18N_CODES.payment}:${badgeType}`) || ''}
        size={Tag.size.Small}
        color={TAG_COLOR_BY_STATUS[badgeType]}
        className={styles.paymentStatus}
        dataQa='payment-status-tooltip'
      />

      {errorMessage
        && badgeType !== BadgeType.Accepted
        && (
          <ToolTip
            text={errorMessage}
            widthMode={ToolTipWidthMode.Max}
            horizontalPosition={HorizontalPositionMode.LeftSide}
            messageClassName={styles.paymentsTooltip}
            className='ml-8'
            dataQa='failed-status-tooltip'
          >
            <IconQuestionRound
              data-qa="icon-question-round"
              className={styles.paymentsTooltipIcon}
            />
          </ToolTip>
        )}
    </div>
  );
};
