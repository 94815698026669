import React, { FC } from 'react';
import { cn } from '@/lib';
import { IconSuccess } from '@/components/ui/icons/IconSuccess';
import { typography } from '@/components/ui/typography';
import { ProgressCircle } from '@/components/platform/common/ProgressCircle';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import { IconCircle } from '@/components/ui/icons/IconCircle';
import {
  DeadlineStatus,
  STATUS_TO_PROGRESS_BAR_CONFIG,
} from '@/components/platform/Payment/PaymentsPage/constants';
import { ToolTip, ToolTipWidthMode } from '@/components/ui/ToolTip';
import { Tag } from '@/components/ui/Tag';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { FCIcon } from '@/components/ui/icons/typedefs';
import styles from './MonthPaymentCardAction.module.scss';

interface Props {
  title: string;
  ctaText: string;
  deadlineStatus: DeadlineStatus;
  handleCTAClick: () => void;
  feeText?: string;
  ctaDisabledReason?: string;
  IconCTA?: FCIcon;
  description?: string;
  deadlineDaysDiff?: number;
  renderBottomContent?: (() => JSX.Element | null) | null;
  renderStatusTag?: (() => JSX.Element | null) | null;
}

export const MonthPaymentCardAction: FC<Props> = ({
  title,
  description,
  deadlineDaysDiff,
  deadlineStatus,
  feeText,
  handleCTAClick,
  ctaDisabledReason = '',
  renderBottomContent,
  renderStatusTag,
  ctaText,
  IconCTA,
}) => {
  const { t } = useTranslation([I18N_CODES.payment]);
  const isCompleted = deadlineStatus === DeadlineStatus.Completed;

  const Icon = isCompleted
    ? IconSuccess
    : IconCircle;

  const deadlineDescription = deadlineStatus === DeadlineStatus.Overdue
    ? t(`${I18N_CODES.payment}:actions.deadline_description.overdue`, {
      days: deadlineDaysDiff,
    })
    : t(`${I18N_CODES.payment}:actions.deadline_description.upcoming`, {
      days: deadlineDaysDiff,
    });

  const {
    pathColor,
    trailColor,
  } = STATUS_TO_PROGRESS_BAR_CONFIG[deadlineStatus];

  const hasBottomContent = Boolean(renderBottomContent);
  const shouldRenderDeadlineBlock = Boolean(
    (!isCompleted && deadlineDaysDiff)
    || renderStatusTag,
  );

  const hasProgressCircle = shouldRenderDeadlineBlock && !renderStatusTag;

  return (
    <div className={cn(styles.container, 'mb-8')} data-qa='payment-card-action-container'>
      <div className={styles.content}>
        <div className={styles.paymentStepTitle}>
          <Icon className={cn({
            [styles.completedStepIcon]: isCompleted,
            [styles.stepInProgressIcon]: !isCompleted,
          })}
          />

          <div>
            <h2 className={typography.platformH2} data-qa="payment-card-action">
              {title}
            </h2>

            {description && !isCompleted && (
              <p className={cn(typography.platformTextSecondary, 'c-text-platform-secondary')} data-qa="payment-card-action-description">
                {description}
              </p>
            )}
          </div>
        </div>

        {shouldRenderDeadlineBlock && (
          <div className={styles.deadlineBlock}>
            <div className={styles.progressCircle} data-qa='deadline-overdue-block'>
              {hasProgressCircle && (
                <>
                  <ProgressCircle
                    progress={deadlineDaysDiff || 0}
                    maxValue={31}
                    size={32}
                    withCaption={false}
                    strokeWidth={20}
                    pathColor={pathColor}
                    trailColor={trailColor}
                    rotation={90}
                  />

                  <h5 className={typography.platformH5} data-qa='amount-overdue-days'>
                    {deadlineDescription}
                  </h5>
                </>
              )}

              {renderStatusTag && renderStatusTag()}

              {feeText && (
                <Tag
                  text={feeText}
                  color={Tag.color.Danger}
                />
              )}
            </div>

            <div className={styles.divider} />
          </div>
        )}

        {!isCompleted && (
          <ToolTip
            className={styles.ctaContainer}
            text={ctaDisabledReason}
            dataQa='payment-button-tooltip'
            isVisibleOnHover={Boolean(ctaDisabledReason)}
            widthMode={ToolTipWidthMode.Max}
          >
            <Button
              size={ButtonSize.Large}
              mode={ButtonMode.Primary}
              className={styles.cta}
              data-qa='action-payment-button'
              text={ctaText}
              onClick={handleCTAClick}
              disabled={Boolean(ctaDisabledReason)}
              LeftIcon={IconCTA}
            />
          </ToolTip>
        )}

        {hasBottomContent && (
          <div className={styles.bottomContent}>
            {renderBottomContent!()}
          </div>
        )}
      </div>
    </div>
  );
};
