import React, { FC, useCallback } from 'react';
import { MonthPaymentCardAction } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/Actions/MonthPaymentCardAction';
import { useRouter, useTranslation } from '@/middleware/i18n';
import { ROUTES } from '@/controllers/router/router.contants';
import { I18N_CODES } from '@/lib/constants/general';
import {
  PostPaidCoursePaymentStatus,
  TodoActionPayment,
} from '@/components/platform/Payment/PaymentsPage/typedefs';
import { CMPDeadline, DeadlineStatus } from '@/components/platform/Payment/PaymentsPage/constants';
import { OverduePaymentAlert } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/Actions/OverduePaymentAlert';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { usePaymentCtaDisabledReason } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/Actions/usePaymentCtaDisabledReason';
import { Tag } from '@/components/ui/Tag';

interface Props {
  payment: TodoActionPayment;
  isRestricted: boolean;
}

export const MakePaymentModule: FC<Props> = ({ payment, isRestricted }) => {
  const { t } = useTranslation([I18N_CODES.payment]);
  const {
    deadlineStatus,
    daysDiff,
    feeData,
    availableAt,
    disabledReason,
    actionStatus,
  } = payment;

  const [authUser] = useAuthUser({ ssr: true });

  const isCompleted = deadlineStatus === DeadlineStatus.Completed;

  const { push } = useRouter();

  const handleMakePayment = useCallback(() => {
    // eslint-disable-next-line deprecation/deprecation
    push(`${ROUTES.user.payment}/new`);
  }, [push]);

  const {
    extraFeeAmount = 0,
    percentageFeeAmount = 0,
    currency = '',
    shouldPayFee,
  } = feeData ?? {};

  const paymentFee = (percentageFeeAmount + extraFeeAmount).toFixed(2);

  const feeText = extraFeeAmount && shouldPayFee
    ? t(`${I18N_CODES.payment}:actions.payment.fee_label_text`, {
      currency,
      fee_amount: paymentFee,
    })
    : '';

  const shouldRenderAlert = !isCompleted && feeText;

  const renderBottomContent = shouldRenderAlert
    ? () => (
      <OverduePaymentAlert
        dueDateDay={authUser?.agreementPaymentDueDate || CMPDeadline.Days}
      />
    )
    : null;

  const ctaDisabledReason = usePaymentCtaDisabledReason({
    isRestricted,
    availableAt,
    disabledReason,
  });

  const renderStatusTag = actionStatus === PostPaidCoursePaymentStatus.Skipped
    ? () => (
      <Tag text={t(`${I18N_CODES.payment}:payment_status.skipped`) || ''} />
    )
    : null;

  return (
    <MonthPaymentCardAction
      title={t(`${I18N_CODES.payment}:actions.payment_title`, {
        percents: authUser?.agreementPercent,
      })}
      deadlineDaysDiff={Math.abs(daysDiff)}
      deadlineStatus={deadlineStatus}
      ctaText={t(`${I18N_CODES.payment}:make_payment`)}
      handleCTAClick={handleMakePayment}
      feeText={feeText}
      ctaDisabledReason={ctaDisabledReason}
      renderBottomContent={renderBottomContent}
      renderStatusTag={renderStatusTag}
    />
  );
};
