import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export enum ManagerUserTimezoneHarnessSelectors {
  DataQa = 'change-timezone-modal',
  NewTimezone = 'new-timezone',
}

export class ManagerUserTimezoneHarness extends ComponentHarness {
  dataQa = ManagerUserTimezoneHarnessSelectors.DataQa;

  getElement() {
    return cy.get(this.makeSelector(this.dataQa));
  }

  newTimezoneMessage(expectedTimezone: string) {
    return cy.get(`[data-qa="${ManagerUserTimezoneHarnessSelectors.NewTimezone}-${expectedTimezone}"]`);
  }

  modalIsNotVisible() {
    this.getElement().should('not.exist');
  }

  checkNewTimezoneModal(expectedTimezone: string) {
    this.getElement().should('exist');
    this.getElement().should('be.visible');

    const newTimezoneMessage = this.newTimezoneMessage(expectedTimezone);

    newTimezoneMessage.should('exist');
  }
}
