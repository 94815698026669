import { DAY } from '@/constants';

export const getDateDiffInDays = (
  a: Date,
  b: Date,
) => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / DAY);
};
