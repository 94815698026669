import React, { FC } from 'react';
import { cn } from '@/lib/classNames';
import { Button } from '@/components/ui/Button';
import { useClickOutside } from '@/hooks/useClickOutside';
import { IconBurger } from '@/components/ui/icons/IconBurger';
import { IconClose } from '@/components/ui/icons/IconClose';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import {
  MobileMenuProfileLink,
} from '@/components/landing/LandingHeader/components/HeaderMobileMenu/components/MobileMenuProfileLink';
import {
  useRouteChangeCallback,
} from '@/controllers/router/router.hooks/useRouteChangeCallback';
import { COURSE_BLOCKS } from '@/components/courseLanding/CoursePage/CoursePageContent.constants';
import styles from './HeaderMobileMenuDeprecated.module.scss';

export const HeaderMobileMenuSinglePage: FC = () => {
  const { active, setActive, ref } = useClickOutside(false);
  const { t } = useTranslation([I18N_CODES.common]);

  const close = () => setActive(false);

  useRouteChangeCallback(close);

  return (
    <div
      ref={ref}
      className={cn({
        [styles.menuOpened]: active,
        [styles.menuClosed]: !active,
      })}
    >
      <Button
        className={styles.triggerButton}
        renderLeftIcon={() => (
          <span className={styles.iconWrapper}>
            <IconBurger className={styles.burgerIcon} />
            <IconClose className={styles.closeIcon} />
          </span>
        )}
        onClick={() => setActive((v) => !v)}
        mode={Button.mode.TransparentLight}
        size={Button.size.Large}
      />

      <div className={styles.menuWrapper}>
        <div className="grid-container">
          <div className={cn('pt-20 pb-12', styles.menuItem)}>
            <div
              className={styles.menuContentItem}
              style={{ '--delay': '0.05s' } as Record<string, string>}
            >
              <Button
                href={`#${COURSE_BLOCKS.aboutPlatform}`}
                text="Why Mate academy"
                title="Why Mate academy"
                size={Button.size.Large}
                mode={Button.mode.TransparentLight}
                className={cn(styles.linkButton, 'mb-4')}
                hasFullWidth
                onClick={close}
              />
            </div>

            <div
              className={styles.menuContentItem}
              style={{ '--delay': '0.1s' } as Record<string, string>}
            >
              <Button
                href={`#${COURSE_BLOCKS.price}`}
                text={t(`${I18N_CODES.common}:price_page_link`)}
                title={t(`${I18N_CODES.common}:price_page_link`)}
                size={Button.size.Large}
                mode={Button.mode.TransparentLight}
                className={cn(styles.linkButton, 'mb-4')}
                hasFullWidth
                onClick={close}
              />
            </div>

            <div
              className={styles.menuContentItem}
              style={{ '--delay': '0.15s' } as Record<string, string>}
            >
              <Button
                href={`#${COURSE_BLOCKS.program}`}
                text="Course roadmap"
                title="Course roadmap"
                size={Button.size.Large}
                mode={Button.mode.TransparentLight}
                className={styles.linkButton}
                hasFullWidth
                onClick={close}
              />
            </div>

            <div
              className={styles.menuContentItem}
              style={{ '--delay': '0.2s' } as Record<string, string>}
            >
              <Button
                href={`#${COURSE_BLOCKS.reviews}`}
                text="Reviews"
                title="Reviews"
                size={Button.size.Large}
                mode={Button.mode.TransparentLight}
                className={styles.linkButton}
                hasFullWidth
                onClick={close}
              />
            </div>

            <div
              className={styles.menuContentItem}
              style={{ '--delay': '0.25s' } as Record<string, string>}
            >
              <Button
                href={`#${COURSE_BLOCKS.faq}`}
                text="FAQ"
                title="FAQ"
                size={Button.size.Large}
                mode={Button.mode.TransparentLight}
                className={styles.linkButton}
                hasFullWidth
                onClick={close}
              />
            </div>
          </div>

          <div className={cn('pt-12 pb-12 hide-for-medium', styles.menuItem)}>
            <div
              className={styles.menuContentItem}
              style={{ '--delay': '0.35s' } as Record<string, string>}
            >
              <MobileMenuProfileLink />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
