import gql from 'graphql-tag';
import { NextMonthPaymentStatus } from '@/controllers/courseMonthPayments/courseMonthPayments.typedefs';

export interface NextMonthPaymentStatusQueryResult {
  nextMonthPaymentStatus: NextMonthPaymentStatus;
}

export const nextMonthPaymentStatus = gql`
  query nextMonthPaymentStatus {
    nextMonthPaymentStatus {
      percentageFeeAmount
      nbuDailyPolicyRate
      feeDays
      extraFeeAmount
      extraFeeCurrency {
        id
        code
      }
      nextMonthPaidOffDate
      paymentDeadline
      isSkipAvailable
      canMakeNextPayment
      makePaymentAvailableDate
      minSalaryAmount
      minSalaryCurrency {
        id
        code
      }
      isSalaryLimitSpecified
      shouldSalaryBeLimited
      shouldPayFee
    }
  }
`;
