import { UserJobsList } from '@/controllers/userJob/userJob.typedefs';
import { useUserJobsCareersQuery } from '@/controllers/userJob/graphql/generated/userJobsCareers.query.generated';

export const useUserJobsCareersList = () => {
  const { data, loading } = useUserJobsCareersQuery();

  const result: UserJobsList = {
    current: [],
    prev: [],
  };

  const content = data?.userJobs ?? [];

  content.forEach((userJob) => {
    if (userJob.lastDay) {
      result.prev.push(userJob);
    } else {
      result.current.push(userJob);
    }
  });

  return { userJobs: result, loading };
};
