import React, { FC } from 'react';
import styles from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/PaymentsHome.module.scss';

export const PaymentsHomeLayout: FC = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.mainContent}>
      {children}
    </div>
  </div>
);
