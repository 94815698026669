import { isPast } from 'date-fns';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { ProofOfRevenueDisabledReason } from '@/components/platform/Payment/PaymentsPage/typedefs';

interface GetProofOfRevenueCtaDisabledReasonOptions {
  availableAt: Date;
  disabledReason?: ProofOfRevenueDisabledReason | null;
}

export const useProofOfRevenueCtaDisabledReason = ({
  disabledReason,
  availableAt,
}: GetProofOfRevenueCtaDisabledReasonOptions) => {
  const { t } = useTranslation([I18N_CODES.payment]);

  if (!disabledReason) {
    // this if-condition relates to previous iteration logic, should be removed as soon as multiple visible todos feature is fully launched
    return isPast(availableAt)
      ? ''
      : t(`${I18N_CODES.payment}:actions.declaration_cta_disabled`, {
        date: availableAt.toLocaleDateString(),
      });
  }

  switch (disabledReason) {
    case ProofOfRevenueDisabledReason.TooEarlyToUpload:
      return t(`${I18N_CODES.payment}:por_disabled.too_early_to_upload`, {
        date: availableAt.toLocaleDateString(),
      });

    case ProofOfRevenueDisabledReason.PreviousProofOfRevenueRequired:
      return t(`${I18N_CODES.payment}:por_disabled.prev_proof_of_revenue_required`);

    case ProofOfRevenueDisabledReason.PendingProofOfRevenue:
      return t(`${I18N_CODES.payment}:por_disabled.pending_proof_of_revenue`);

    default:
      return '';
  }
};
