import React, { FC } from 'react';
import { MonthPaymentCard } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/MonthPaymentCard';
import { JobSearchActionType, MonthTodo, TodoStatus } from '@/components/platform/Payment/PaymentsPage/typedefs';
import styles from './CareerNotFilledTodo.module.scss';

export const CareerNotFilledTodo: FC = () => {
  const TODO_DATA: MonthTodo = {
    date: new Date(),
    status: TodoStatus.Unemployed,
    payment: null,
    proofOfRevenue: null,
    jobSearchActions: [JobSearchActionType.FindJob],
  };

  return (
    <div className={styles.container}>
      <MonthPaymentCard
        todo={TODO_DATA}
        isPaymentRestricted={false}
      />
    </div>
  );
};
