export const MOCKED_COURSE_SLUG = 'frontend';

export const MODULE_CARD_DATA_QA = {
  MODULE_CARD: 'module-card',
  PLANNED_RELEASE_TAG: 'planned-release-tag',
  COMING_SOON_TAG: 'coming-soon-tag',
  MODULE_CARD_TITLE: 'course-module-title',
  MODULE_CARD_PROGRESS_BAR: 'module-card-progress-bar',
  MODULE_CARD_TOPICS_BUTTON: 'module-card-topics-button',
};
