import React, {
  Dispatch, FC, SetStateAction, useMemo,
} from 'react';
import { PaymentStatus } from '@/controllers/graphql/generated';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { SelectUi } from '@/components/ui/FormElements/FormInputs/Select';
import styles from './StatusFilter.module.scss';

interface Props {
  setPaymentStatus: Dispatch<SetStateAction<PaymentStatus | undefined>>;
}

export const StatusFilter: FC<Props> = ({
  setPaymentStatus,
}) => {
  const { t } = useTranslation([I18N_CODES.payment]);

  const statusFilterOptions = useMemo(() => ([
    {
      value: '',
      label: t(`${I18N_CODES.payment}:label_all`),
    },
    {
      value: `${PaymentStatus.Accepted}`,
      label: t(`${I18N_CODES.payment}:label_accepted`),
    },
    {
      value: `${PaymentStatus.Failed}`,
      label: t(`${I18N_CODES.payment}:label_declined`),
    },
  ]), [t]);

  return (
    <div className={styles.filter}>
      <SelectUi
        isSearchable={false}
        options={statusFilterOptions}
        name="status"
        id="status-filter"
        placeholder={t(`${I18N_CODES.payment}:label_all`)}
        onChange={(event) => {
          setPaymentStatus(event?.value as PaymentStatus | undefined);
        }}
      />
    </div>
  );
};
