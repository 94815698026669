import React, { FC, useCallback } from 'react';
import {
  HeaderCoursesDropdown,
} from '@/components/landing/LandingHeader/components/HeaderCoursesDropdown';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { ActiveLink } from '@/components/common/ActiveLink';
import { ROUTES } from '@/controllers/router/router.contants';
import { Button } from '@/components/ui/Button';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { useDefaultDomain } from '@/controllers/subDomain/subDomain.hooks/useDefaultDomain';
import { useTranslateByDomain } from '@/hooks/useTranslateByDomain';
import { analyticsSDK } from '@/controllers/analytics';
import { HireGraduateFormSource } from '@/controllers/analytics/generated';
import { cn } from '@/lib';
import styles from './HeaderNavigation.module.scss';

export const HeaderNavigation: FC = () => {
  const features = useFeatures();
  const { t } = useTranslation([I18N_CODES.common]);

  const isDefaultDomain = useDefaultDomain();

  const hireFormLink = useTranslateByDomain(
    `${I18N_CODES.common}:hire_form_link`,
  );

  const shouldShowReviewsPageLink = isDefaultDomain
    && features.isEnabled(features.ReviewsLanding);

  const onHireFormLinkClick = useCallback(() => {
    analyticsSDK.landing.sendHireGraduateFormOpenedEvent({
      source: HireGraduateFormSource.Header,
    });
  }, []);

  return (
    <nav
      itemScope
      itemType="http://schema.org/SiteNavigationElement"
    >
      <ul
        itemProp="about"
        itemScope
        itemType="http://schema.org/ItemList"
        className="flex-container"
      >
        <li
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
        >
          <HeaderCoursesDropdown />
        </li>

        <li
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
          className={cn(styles.showOnXLarge, 'ml-8')}
        >
          <ActiveLink href={ROUTES.price}>
            <Button
              href={ROUTES.price}
              text={t(`${I18N_CODES.common}:price_page_link`)}
              title={t(`${I18N_CODES.common}:price_page_link`)}
              data-qa="price_page_link"
              size={Button.size.Large}
              mode={Button.mode.TransparentLight}
              className={styles.linkButton}
            />
          </ActiveLink>
        </li>

        {shouldShowReviewsPageLink && (
          <li
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
            className={styles.showOnXLarge}
          >
            <ActiveLink href={ROUTES.reviews}>
              <Button
                href={ROUTES.reviews}
                text={t(`${I18N_CODES.common}:reviews_page_link`)}
                title={t(`${I18N_CODES.common}:reviews_page_link`)}
                data-qa="review_page_link"
                size={Button.size.Large}
                mode={Button.mode.TransparentLight}
                className={styles.linkButton}
              />
            </ActiveLink>
          </li>
        )}

        <li
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
          className={styles.showOnXLarge}
        >
          <ActiveLink href={ROUTES.team}>
            <Button
              href={ROUTES.team}
              text={t(`${I18N_CODES.common}:company_page_link`)}
              data-qa="company_page_link"
              title={t(`${I18N_CODES.common}:company_page_link`)}
              size={Button.size.Large}
              mode={Button.mode.TransparentLight}
              className={styles.linkButton}
            />
          </ActiveLink>
        </li>

        {hireFormLink && (
          <li
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
            className={styles.showOnXLarge}
          >
            <Button
              href={hireFormLink}
              text={t(`${I18N_CODES.common}:hire_page_link`)}
              title={t(`${I18N_CODES.common}:hire_page_link`)}
              size={Button.size.Large}
              mode={Button.mode.TransparentLight}
              target="_blank"
              className={styles.linkButton}
              onClick={onHireFormLinkClick}
            />
          </li>
        )}
      </ul>
    </nav>
  );
};
