import React, { FC } from 'react';
import {
  PaymentMonthProgressBar,
} from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/PaymentMonthProgressBar';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { MakePaymentModule } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/Actions/MakePaymentModule';
import { UploadDeclarationModule } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/Actions/UploadDeclarationModule';
import { DeadlineStatus } from '@/components/platform/Payment/PaymentsPage/constants';
import { MonthTodo } from '@/components/platform/Payment/PaymentsPage/typedefs';
import { JobSearchModule } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/Actions/JobSearchModule';
import {
  useNextProofOfRevenueStatus,
} from '@/controllers/proofOfRevenue/proofOfRevenue.hooks/useNextProofOfRevenueStatus';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';

interface Props {
  todo: MonthTodo;
  isContentVisible: boolean;
  isPaymentRestricted: boolean;
}

const getStepsCompleted = (
  isPaymentCompleted: boolean,
  isProofOfRevenueCompleted: boolean,
) => {
  if (isPaymentCompleted && isProofOfRevenueCompleted) {
    return 2;
  }

  return isPaymentCompleted || isProofOfRevenueCompleted
    ? 1
    : 0;
};

export const MonthPaymentCardActions: FC<Props> = ({
  todo,
  isContentVisible,
  isPaymentRestricted,
}) => {
  const [authUser] = useAuthUser({ ssr: true });
  const { isEnabled } = useFeatures();

  const { payment, proofOfRevenue, jobSearchActions } = todo;
  const { nextProofOfRevenueStatus } = useNextProofOfRevenueStatus();
  const { shouldUploadProofOfRevenue } = nextProofOfRevenueStatus ?? {};

  if (!authUser || !isContentVisible) {
    return null;
  }

  const isMultipleVisibleTodosFeatureEnabled = isEnabled(
    Features.ShowMultiplePostPaidCourseTodos,
  );

  const shouldRenderProofOfRevenueModuleByStatus = proofOfRevenue && ( // old flow
    shouldUploadProofOfRevenue
    || proofOfRevenue.deadlineStatus === DeadlineStatus.Completed
  );

  const shouldRenderProofOfRevenueModule = proofOfRevenue && (
    isMultipleVisibleTodosFeatureEnabled
    || shouldRenderProofOfRevenueModuleByStatus
  );

  const stepsCompleted = getStepsCompleted(
    payment?.deadlineStatus === DeadlineStatus.Completed,
    proofOfRevenue?.deadlineStatus === DeadlineStatus.Completed,
  );

  return (
    <>
      {payment && proofOfRevenue && (
        <PaymentMonthProgressBar
          stepsCount={2}
          stepsCompleted={stepsCompleted}
        />
      )}

      {payment && (
        <MakePaymentModule
          payment={payment}
          isRestricted={isPaymentRestricted}
        />
      )}

      {shouldRenderProofOfRevenueModule && (
        <UploadDeclarationModule proofOfRevenue={proofOfRevenue} />
      )}

      {jobSearchActions && (
        <JobSearchModule actions={jobSearchActions} />
      )}
    </>
  );
};
