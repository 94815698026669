import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';

export const usePaymentTableHeaders = () => {
  const {
    t,
  } = useTranslation([I18N_CODES.payment]);

  return {
    date: {
      title: t(`${I18N_CODES.payment}:payment_salary_date`),
    },
    salary: {
      title: t(`${I18N_CODES.payment}:salary_amount`),
    },
    amount: {
      title: t(`${I18N_CODES.payment}:payment_amount`),
    },
    rate: {
      title: t(`${I18N_CODES.payment}:exchange_rate`),
    },
    status: {
      title: t(`${I18N_CODES.payment}:payment_status`),
    },
    receipt: {
      title: t(`${I18N_CODES.payment}:payment_receipt`),
    },
  };
};
