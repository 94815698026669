import { isPast, isSameDay } from 'date-fns';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { PaymentDisabledReason } from '@/components/platform/Payment/PaymentsPage/typedefs';

interface GetPaymentCtaDisabledReasonOptions {
  isRestricted: boolean;
  disabledReason?: PaymentDisabledReason | null;
  availableAt?: Date;
}

const disabledReasonToTranslateCodeMap = {
  [PaymentDisabledReason.PreviousPaymentNotCompleted]: 'payment_disabled.prev_payment_not_completed',
  [PaymentDisabledReason.OverdueProofOfRevenue]: 'payment_disabled.overdue_proof_of_revenue',
  [PaymentDisabledReason.TooEarlyToPay]: 'payment_disabled.too_early_to_pay',
  [PaymentDisabledReason.PendingProofOfRevenue]: 'payment_disabled.pending_proof_of_revenue',
  [PaymentDisabledReason.IsManuallyRestricted]: 'actions.payment.cmp_restricted',
};

export const usePaymentCtaDisabledReason = ({
  disabledReason,
  isRestricted,
  availableAt,
}: GetPaymentCtaDisabledReasonOptions) => {
  const { t } = useTranslation([I18N_CODES.payment]);

  if (disabledReason === PaymentDisabledReason.TooEarlyToPay) {
    return availableAt
      ? t(`${I18N_CODES.payment}:payment_disabled.too_early_to_pay`, {
        date: new Date(availableAt).toLocaleDateString(),
      })
      : t(`${I18N_CODES.payment}:payment_disabled.too_early_to_pay`);
  }

  if (disabledReason) {
    const code = disabledReasonToTranslateCodeMap[disabledReason];

    return t(`${I18N_CODES.payment}:${code}`);
  }

  // everything below this line should be removed as soon as multiple visible todos feature is fully launched

  if (isRestricted) {
    return t(`${I18N_CODES.payment}:actions.payment.cmp_restricted`);
  }

  const paymentAvailableAt = new Date(availableAt ?? new Date());

  return isPast(paymentAvailableAt) || isSameDay(paymentAvailableAt, new Date())
    ? ''
    : t(`${I18N_CODES.payment}:actions.payment.cta_disabled`, {
      date: paymentAvailableAt.toLocaleDateString(),
    });
};
