import { useMemo } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';

export const useCalendarMonthsNames = () => {
  const { t } = useTranslation([I18N_CODES.schedule]);

  const months = useMemo(() => ([
    t(`${I18N_CODES.schedule}:month-name_january`),
    t(`${I18N_CODES.schedule}:month-name_february`),
    t(`${I18N_CODES.schedule}:month-name_march`),
    t(`${I18N_CODES.schedule}:month-name_april`),
    t(`${I18N_CODES.schedule}:month-name_may`),
    t(`${I18N_CODES.schedule}:month-name_june`),
    t(`${I18N_CODES.schedule}:month-name_july`),
    t(`${I18N_CODES.schedule}:month-name_august`),
    t(`${I18N_CODES.schedule}:month-name_september`),
    t(`${I18N_CODES.schedule}:month-name_october`),
    t(`${I18N_CODES.schedule}:month-name_november`),
    t(`${I18N_CODES.schedule}:month-name_december`),
  ]), [t]);

  return months;
};
