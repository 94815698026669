import React, { FC } from 'react';
import { Alert, AlertMode } from '@/components/ui/Alert';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { I18N_CODES } from '@/lib/constants/general';
import {
  CourseMonthPaymentErrors,
} from '@/components/platform/Payment/CourseMonthPaymentModule/PaymentAlert/PaymentAlert.typedefs';
import { MonthPaymentCards } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/MonthPaymentCards';
import { useTranslation } from '@/middleware/i18n';
import { MonthTodo } from '@/components/platform/Payment/PaymentsPage/typedefs';

interface Props {
  courseMonthPaymentRestricted: boolean;
  todos: MonthTodo[];
}

export const PaymentsHomeContent: FC<Props> = ({
  courseMonthPaymentRestricted,
  todos,
}) => {
  const { t } = useTranslation([I18N_CODES.payment]);

  return (
    <div className='w-100'>
      {courseMonthPaymentRestricted && (
        <Alert mode={AlertMode.Error} className='mb-24'>
          <p
            data-qa='payment-alert-text'
            className={cn(
              typography.platformTextMain,
              typography.paragraph,
            )}
            dangerouslySetInnerHTML={{
              __html: t(`${I18N_CODES.payment}:${CourseMonthPaymentErrors.CourseMonthPaymentRestricted}`),
            }}
          />
        </Alert>
      )}

      <MonthPaymentCards
        todos={todos}
        isPaymentRestricted={courseMonthPaymentRestricted}
      />
    </div>
  );
};
