import React, { FC } from 'react';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { PaymentsHome } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/PaymentsHome';
import { PaymentsHomeWithGeneratedTodos } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/PaymentsHomeWithGeneratedTodos';

export const PaymentsHomeByFeatureStatus: FC = () => {
  const { isEnabled } = useFeatures();

  const isMultipleVisibleTodosFeatureEnabled = isEnabled(
    Features.ShowMultiplePostPaidCourseTodos,
  );

  if (!isMultipleVisibleTodosFeatureEnabled) {
    return <PaymentsHomeWithGeneratedTodos />;
  }

  return <PaymentsHome />;
};
