import React from 'react';
import { NextPage } from 'next';
import NextErrorComponent from 'next/error';
import { NextSeo } from 'next-seo';
import { I18N_CODES } from '@/lib/constants/general';
import { withLayout } from '@/controllers/layout/layout.hocs/withLayout';
import { compose } from '@/lib/compose';
import { withNamespaces } from '@/controllers/i18n/i18n.hocs/withNamespaces';
import { PageProps } from '@/controllers/page/page.typedefs';
import { ErrorModule } from '@/components/error/ErrorModule';
import { errorHandler } from '@/core/ErrorHandler';
import { CTAContextProvider } from '@/controllers/cta/Cta.context';
import { COURSE_BLOCKS } from '@/components/courseLanding/CoursePage/CoursePageContent.constants';
import { AnalyticsEvents } from '@/controllers/analytics/analytics.events';
import { useTranslation } from '@/middleware/i18n';
import { ROUTES } from '@/controllers/router/router.contants';
import { Ctx } from '@/typedefs/pageProps';
import { AuthUserErrorModule } from '@/components/error/AuthUserErrorModule';
import { PlatformLayout } from '@/components/layout/scss/PlatformLayout';
import { EmptyLandingLayout } from '@/components/layout/scss/EmptyLandingLayout';
import { EMPTY_OBJECT } from '@/constants';
import { AuthUserDocument, AuthUserQuery } from '@/controllers/user/graphql/generated/authUser.query.generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

interface Props extends PageProps {
  statusCode?: number;
  isAuth?: boolean;
}

const ErrorPage: NextPage<Props> = (props) => {
  const { statusCode, isAuth } = props;
  const { t } = useTranslation([I18N_CODES.home]);

  const { isKnowelyProduct } = useSubDomainContext();

  const title = isKnowelyProduct
    ? 'Knowely'
    : 'Mate academy';

  return (
    <>
      <NextSeo
        title={title}
        description=""
        titleTemplate="%s"
      />

      <CTAContextProvider
        value={{
          text: t(`${I18N_CODES.common}:study_for_free`),
          href: `${ROUTES.home}#${COURSE_BLOCKS.allCourses}`,
          callback: (data: Record<string, any> = {}) => {
            AnalyticsEvents.sendEvent(
              AnalyticsEvents.events.courseCTAClicked,
              data,
            );
          },
        }}
      >
        {isAuth
          ? (
            <PlatformLayout layoutOptions={EMPTY_OBJECT}>
              <AuthUserErrorModule code={statusCode} />
            </PlatformLayout>
          )
          : (
            <ErrorModule code={statusCode} />
          )}
      </CTAContextProvider>
    </>
  );
};

ErrorPage.getInitialProps = async (ctx: Ctx) => {
  const { err: nextError } = ctx;

  const errorInitialProps = await NextErrorComponent.getInitialProps(ctx);

  const error = nextError;

  if (error) {
    errorHandler.captureException(error);
  }

  const { data } = await ctx.apolloClient.query<AuthUserQuery>({
    query: AuthUserDocument,
    fetchPolicy: 'no-cache',
  });

  const isAuth = !!data?.authUser;

  return {
    ...errorInitialProps,
    isAuth,
  };
};

export default compose(
  withLayout(EmptyLandingLayout),
  withNamespaces([
    I18N_CODES.common,
    I18N_CODES.error,
    I18N_CODES.seo,
  ]),
)(ErrorPage);
