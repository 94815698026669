import React, { DetailedHTMLProps, FC } from 'react';
import { cn } from '@/lib/classNames';
import { TableWidths, TextAlignValues } from '@/components/ui/Table/Table.typedefs';
import tableStyles from '@/components/ui/Table/Table.module.scss';
import { exists } from '@/lib/helpers/functional';
import styles from './TableCell.module.scss';

type TableCellElementProps = DetailedHTMLProps<
  React.TdHTMLAttributes<HTMLTableDataCellElement>,
  HTMLTableDataCellElement
>;

interface CustomProps {
  textAlign?: TextAlignValues;
  width?: TableWidths;
  defaultStylesOnMobileScreen?: boolean;
  dataQa?: string;
}

export type TableCellProps = TableCellElementProps & CustomProps;

export const TableCell: FC<TableCellProps> = (props) => {
  const {
    children,
    className,
    textAlign,
    width,
    defaultStylesOnMobileScreen = false,
    dataQa = 'table-cell',
    ...rest
  } = props;

  const classes = cn(
    styles.tableCell,
    className,
    { [styles.mobile]: !defaultStylesOnMobileScreen },
    textAlign
      ? tableStyles[textAlign]
      : tableStyles.left,
    {
      ...(
        exists(width)
          ? {
            [tableStyles[`width-${width}`]]: width,
          }
          : {}
      ),
    },
  );

  return (
    <td {...rest} className={classes} data-qa={dataQa}>
      {children}
    </td>
  );
};
