import { useEffect } from 'react';
import { LanguageName } from '@/controllers/graphql/generated';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { DEFAULT_SUB_DOMAIN } from '@/middleware/i18n/i18n.config';
import { AuthUserDocument, useAuthUserQuery } from '@/controllers/user/graphql/generated/authUser.query.generated';
import { useUpdateUserDomainAndLanguageMutation } from '@/controllers/user/graphql/generated/updateUserDomainAndLanguage.mutation.generated';

export const useAuthUserRuLanguageHandler = () => {
  const { data: authUserData } = useAuthUserQuery({
    ssr: false,
  });

  const { subDomain, language } = useSubDomainContext();

  const [
    updateDomainAndLanguage,
    { called },
  ] = useUpdateUserDomainAndLanguageMutation({
    refetchQueries: [
      { query: AuthUserDocument },
    ],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (
      !called
            && subDomain === DEFAULT_SUB_DOMAIN
            && language === LanguageName.Ru
            && Boolean(authUserData?.authUser?.id)
    ) {
      updateDomainAndLanguage({
        variables: {
          domainSlug: subDomain,
          languageName: LanguageName.Uk,
        },
      });
    }
  }, [
    authUserData?.authUser?.id,
    called,
    language,
    subDomain,
    updateDomainAndLanguage,
  ]);
};
