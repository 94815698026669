import React, { FC, memo } from 'react';
import { useUserProfileContext } from '@/components/platform/UserProfile/UserProfileContext';
import { PlatformSidebar } from '@/components/ui/PlatformSidebar';
import { ViewsMap } from '@/components/platform/UserProfile/typedefs';
import styles from '@/components/platform/UserProfile/UserProfile.module.scss';
import { Paywall } from '@/components/platform/SubscriptionProduct/components/Paywall';
import { PaywallLocations } from '@/controllers/analytics/generated';
import { useRouteChangeCallback } from '@/controllers/router/router.hooks/useRouteChangeCallback';

export const UserProfile: FC = memo(() => {
  const {
    userId,
    mode,
    closeProfile,
    shouldUseDMsPaywall,
    closePaywall,
    isPaywallOpened,
  } = useUserProfileContext();

  useRouteChangeCallback(closeProfile);

  const CurrentView = ViewsMap[mode];

  return (
    <>
      <PlatformSidebar
        closeSidebar={closeProfile}
        isSidebarOpen={Boolean(userId)}
        classNames={styles.sidebar}
        sidebarClassName={styles.sidebarContent}
      >
        <CurrentView />
      </PlatformSidebar>

      {shouldUseDMsPaywall && (
        <Paywall
          isOpen={isPaywallOpened}
          closePaywall={closePaywall}
          paywallLocation={PaywallLocations.UserProfile}
        />
      )}
    </>
  );
});
