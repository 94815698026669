import React, { FC, memo } from 'react';
import { cn } from '@/lib/classNames';
import { Dropdown } from '@/components/common/Dropdown';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { ProfileDropdownContentItems } from '@/components/platform/ProfileDropdown/ProfileDropdownContentItems';
import { Selectors } from '@/lib/selectors';
import { ProfileAvatar } from '../ProfileAvatar';
import styles from './SidebarDropdown.module.scss';

export const SidebarDropdown: FC = memo(
  () => {
    const [authUser] = useAuthUser({ ssr: true });

    if (!authUser) {
      return null;
    }

    const username = authUser.firstName
    && authUser.lastName
      ? `${authUser.firstName} ${authUser.lastName}`
      : authUser.email;

    return (
      <Dropdown
        icon={<ProfileAvatar size={40} />}
        title={username}
        large
        isFullWidth
        className={styles.wrapper}
        renderContent={({ isActive }) => (
          <ul className={cn(
            styles.dropdownContent,
            {
              [Selectors.DropdownOpened]: isActive,
            },
          )}
          >
            <ProfileDropdownContentItems isOpened={isActive} />
          </ul>
        )}
      />
    );
  },
);
