import React, { FC } from 'react';
import { MonthTodo } from '@/components/platform/Payment/PaymentsPage/typedefs';
import { MonthPaymentCard } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/Card/MonthPaymentCard';
import styles from './MonthPaymentCards.module.scss';

interface Props {
  todos: MonthTodo[];
  isPaymentRestricted: boolean;
}

export const MonthPaymentCards: FC<Props> = ({
  todos,
  isPaymentRestricted,
}) => (
  <div className={styles.container}>
    {todos.map((todo) => (
      <MonthPaymentCard
        todo={todo}
        key={todo.date?.getTime()}
        isPaymentRestricted={isPaymentRestricted}
      />
    ))}
  </div>
);
