import React, { FC } from 'react';
import { PaymentsProgressWidgets } from '@/components/platform/Payment/PaymentsPage/Common/Widgets/PaymentsProgressWidgets';
import styles from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/PaymentsHome.module.scss';
import { CareerNotFilledTodo } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/CareerNotFilledTodo';
import { useUserJobsCareersList } from '@/controllers/userJob/userJob.hooks/useUserJobsCareersList';
import { Loader } from '@/components/ui/Loader';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { StudentStatus } from '@/controllers/graphql/generated';
import { GraduatedBlock } from '@/components/platform/Payment/PaymentsDashboard/GraduatedBlock/GraduatedBlock';
import { PaymentsHomeContent } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/PaymentsHomeContent';
import { usePaymentTodosDeprecated } from '@/components/platform/Payment/PaymentsPage/Pages/HomePage/usePaymentTodosDeprecated';
import { PaymentsHomeLayout } from '@/components/platform/Payment/PaymentsPage/Common/Layout/PaymentsHomeLayout';

export const PaymentsHomeWithGeneratedTodos: FC = () => {
  const { userJobs, loading: userJobsLoading } = useUserJobsCareersList();
  const {
    todos,
    loading: todosLoading,
  } = usePaymentTodosDeprecated(userJobs);

  const [authUser] = useAuthUser({ ssr: true });

  if (!authUser) {
    return null;
  }

  const {
    studentStatus,
    courseMonthPaymentRestricted,
  } = authUser;

  const loading = userJobsLoading || todosLoading;

  if (loading) {
    return (
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <Loader size={50} loading={loading} />
        </div>
      </div>
    );
  }

  if (studentStatus === StudentStatus.Graduated) {
    return (
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <PaymentsProgressWidgets />

          <div className={styles.graduatedBlock}>
            <GraduatedBlock />
          </div>
        </div>
      </div>
    );
  }

  if (!userJobs.current.length && !userJobs.prev.length) {
    return (
      <div className={styles.container}>
        <CareerNotFilledTodo />
      </div>
    );
  }

  return (
    <PaymentsHomeLayout>
      <PaymentsProgressWidgets />

      <PaymentsHomeContent
        todos={todos}
        courseMonthPaymentRestricted={courseMonthPaymentRestricted}
      />
    </PaymentsHomeLayout>
  );
};
