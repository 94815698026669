import { useMemo } from 'react';
import { useNextProofOfRevenueStatusQuery } from '@/controllers/proofOfRevenue/graphql/generated/nextProofOfRevenueStatus.query.generated';
import { NextProofOfRevenueStatus } from '@/controllers/proofOfRevenue/typedefs';

export interface NextProofOfRevenueStatusHook {
  (): {
    nextProofOfRevenueStatus: NextProofOfRevenueStatus | null;
    loading: boolean;
  };
}

export const useNextProofOfRevenueStatus: NextProofOfRevenueStatusHook = () => {
  const queryResult = useNextProofOfRevenueStatusQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { data, loading, error } = queryResult;
  const ready = !(loading || error);

  const status = useMemo(() => {
    if (ready && data) {
      const { nextProofOfRevenueStatus } = data;

      return nextProofOfRevenueStatus;
    }

    return null;
  }, [ready, data]);

  return {
    nextProofOfRevenueStatus: status,
    loading,
  };
};
