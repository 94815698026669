import React, { FC, useState } from 'react';
import { useUserCourseMonthPayments } from '@/controllers/courseMonthPayments/courseMonthPayments.hooks';
import {
  Table, TableHead, TableHeader, TablePlaceholder, TableRow,
} from '@/components/ui/Table';
import { PaymentsTableBody } from '@/components/platform/Payment/PaymentsTable/PaymentsTableBody';
import { usePaymentTableHeaders } from '@/components/platform/Payment/PaymentsTable/PaymentTable.hooks/usePaymentTableHeaders';
import { StatusFilter } from '@/components/platform/Payment/PaymentsTable/StatusFilter/StatusFilter';
import { PaymentStatus } from '@/controllers/graphql/generated';
import { ChubbsPlaceholder } from '@/components/ui/ChubsPlaceholder/ChubbsPlaceholder';
import {
  ChubbsPlaceholderBackground,
  ChubbsPlaceholderType,
} from '@/components/ui/ChubsPlaceholder/chubbsPlaceholderType';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import styles from '@/components/platform/Payment/PaymentsTable/PaymentsTable.module.scss';

export const PaymentsTable: FC = () => {
  const { t } = useTranslation([I18N_CODES.payment]);
  const features = useFeatures();
  const shouldShowPaymentReceipt = features.isEnabled(
    Features.ShowPaymentReceiptLink,
  );
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>();

  const [payments, { loading }] = useUserCourseMonthPayments();

  const headers = usePaymentTableHeaders();

  const columnsCount = shouldShowPaymentReceipt
    ? Object.entries(headers).length
    : Object.entries(headers).length - 1;

  if (!payments.length) {
    return (
      <ChubbsPlaceholder
        chubbsType={ChubbsPlaceholderType.Typing}
        textPrimary={t(`${I18N_CODES.payment}:statistics.chubbs_placeholder.title`)}
        backgroundColor={ChubbsPlaceholderBackground.Info}
      />
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader width={4}>
            {headers.date.title}
          </TableHeader>

          <TableHeader width={2}>
            {headers.salary.title}
          </TableHeader>

          <TableHeader width={2}>
            {headers.amount.title}
          </TableHeader>

          {shouldShowPaymentReceipt && (
            <TableHeader width={2}>
              {headers.receipt.title}
            </TableHeader>
          )}

          <TableHeader width={2}>
            <div className={styles.statusFilterHeader}>
              <span>{headers.status.title}</span>

              <StatusFilter
                setPaymentStatus={setPaymentStatus}
              />
            </div>
          </TableHeader>
        </TableRow>
      </TableHead>

      {loading
        ? <TablePlaceholder />
        : (
          <PaymentsTableBody
            filterStatus={paymentStatus}
            columnsCount={columnsCount}
          />
        )}
    </Table>
  );
};
