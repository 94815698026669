import React from 'react';
import { cn } from '@/lib/classNames';
import i18Next, { useTranslation } from '@/middleware/i18n';
import { ROUTES } from '@/controllers/router/router.contants';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { I18N_CODES } from '@/lib/constants/general';
import { SidebarItem } from '@/components/platform/SidebarItem';
import { IconVacancies } from '@/components/ui/icons/IconVacancies';
import { IconCoursePlay } from '@/components/ui/icons/IconCoursePlay';
import { StarIcon } from '@/components/ui/icons/StarIcon';
import { EnglishIcon } from '@/components/ui/icons/EnglishIcon';
import { IconPayments } from '@/components/ui/icons/IconPayments';
import { isActiveUser, isUserLookingForJob, shouldUserPay } from '@/controllers/user/user.utils/userStatus.selectors';
import { ChatsSidebarItem } from '@/components/platform/Sidebar/ChatsSidebarItem';
import { CalendarSidebarIcon } from '@/components/platform/Sidebar/CalendarSidebarIcon';
import { IconGroup } from '@/components/ui/icons/IconGroup';
import { userAccessController } from '@/controllers/user/user.hooks/useHasAccess';
import { Permission } from '@/controllers/user/Permission';
import { isFulltimeStudent } from '@/controllers/user/user.utils';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { PaymentTab } from '@/components/platform/Payment/PaymentsPage/constants';
import { renderRoute, allRoutes } from '@/controllers/router/allRoutes';
import {
  SubDomainFeatures,
} from '@/controllers/features/subDomainFeature.config';
import { IconHomeStroke } from '@/components/ui/icons/IconHomeStroke';
import styles from './Sidebar.module.scss';

export const StudentSidebarItems: React.FC = () => {
  const { t } = useTranslation([I18N_CODES.platformSidebar]);
  const router = i18Next.useRouter();
  const {
    isEnabled,
    SidebarNavCourses,
    SidebarNavDashboard,
    SidebarNavCalendar,
    SidebarNavChats,
    SidebarNavEvents,
    SidebarNavEnglish,
    SidebarNavVacancies,
    SidebarNavPayments,
    HomePage,
  } = useFeatures();
  const [authUser] = useAuthUser({ ssr: true });
  const {
    isSubscriptionProductDomain,
  } = useSubDomainContext();

  const isHomePageEnabled = isEnabled(HomePage);
  const homeRoute = renderRoute(allRoutes.home);

  const isActiveLearnSidebarItem = router.pathname
    .includes(renderRoute(allRoutes.learn, {}))
    || router.pathname.includes(renderRoute(allRoutes.study))
    || router.pathname.includes(ROUTES.user.assignment);

  const hasUserAccessToAdminDashboard = userAccessController.useHasAccess(
    Permission.GroupDashboardView,
  );
  const hasAccessToVacancies = userAccessController.useHasAccess(
    Permission.VacanciesUse,
  );

  if (!authUser) {
    return null;
  }

  const isUserActiveFTStudent = isFulltimeStudent(authUser)
    && isActiveUser(authUser.studentStatus);

  const shouldRenderHomeItem = isHomePageEnabled;
  const shouldRenderCoursesItem = (
    isEnabled(SidebarNavCourses)
  );
  const shouldRenderGroupDashboardItem = (
    isEnabled(SidebarNavDashboard)
    && (hasUserAccessToAdminDashboard || isUserActiveFTStudent)
  );
  const shouldRenderPaymentItem = (
    isEnabled(SidebarNavPayments)
    && shouldUserPay(authUser.studentStatus)
    && isFulltimeStudent(authUser)
  );
  const paymentRedirectUrl = isEnabled(Features.PaymentsPageRedesign)
    ? ROUTES.payments.tab(PaymentTab.Home)
    : ROUTES.payments.index;

  const shouldRenderScheduleItem = (
    isEnabled(SidebarNavCalendar)
    && !isSubscriptionProductDomain
  );
  const shouldRenderChatsItem = (
    isEnabled(SidebarNavChats)
  );
  const shouldRenderEventsItem = (
    isEnabled(SidebarNavEvents)
    && !isSubscriptionProductDomain
  );
  const shouldRenderEnglishItem = (
    isEnabled(SidebarNavEnglish)
    && isEnabled(SubDomainFeatures.EnglishTab)
    && !isSubscriptionProductDomain
  );
  const shouldRenderVacanciesItem = (
    isEnabled(SidebarNavVacancies)
    && hasAccessToVacancies
    && isUserLookingForJob(authUser.studentStatus)
  );

  return (
    <>
      {shouldRenderHomeItem && (
        <li className={styles.navItem}>
          <SidebarItem
            className={cn({
              [styles.activeSidebarItem]:
                router.pathname.includes(homeRoute),
            })}
            icon={<IconHomeStroke className={styles.sidebarIcon} />}
            text={t(`${I18N_CODES.platformSidebar}:sidebar.home_link`)}
            href={homeRoute}
            dataQa='home-link'
          />
        </li>
      )}

      {shouldRenderCoursesItem && (
        <li className={styles.navItem}>
          <SidebarItem
            className={cn({
              [styles.activeSidebarItem]:
              isActiveLearnSidebarItem,
            })}
            icon={<IconCoursePlay className={styles.sidebarIcon} />}
            text={t(`${I18N_CODES.platformSidebar}:sidebar.courses_link`)}
            href={renderRoute(allRoutes.study)}
            dataQa='learn-link'
          />
        </li>
      )}

      {shouldRenderGroupDashboardItem && (
        <li className={styles.navItem}>
          <SidebarItem
            icon={<IconGroup className={styles.sidebarIcon} />}
            className={cn({
              [styles.activeSidebarItem]:
                router.pathname.startsWith('/group'),
            })}
            text={t(`${I18N_CODES.platformSidebar}:group_dashboard_link`)}
            href={ROUTES.groupDashboard.group.index}
            dataQa="group-dashboard-link"
          />
        </li>
      )}

      {shouldRenderScheduleItem && (
        <CalendarSidebarIcon />
      )}

      {shouldRenderChatsItem && (
        <ChatsSidebarItem />
      )}

      {shouldRenderEventsItem && (
        <li className={styles.navItem}>
          <SidebarItem
            className={cn({
              [styles.activeSidebarItem]:
                router.pathname.includes(ROUTES.user.events),
            })}
            icon={<StarIcon className={styles.sidebarIcon} />}
            text={t(`${I18N_CODES.platformSidebar}:sidebar.events_link`)}
            href={ROUTES.user.events}
            dataQa='events-link'
          />
        </li>
      )}

      {shouldRenderEnglishItem && (
        <li className={styles.navItem}>
          <SidebarItem
            icon={<EnglishIcon className={styles.sidebarIcon} />}
            className={cn({
              [styles.activeSidebarItem]:
                  router.pathname.includes(ROUTES.user.english),
            })}
            text={t(`${I18N_CODES.platformSidebar}:english_link`)}
            href={ROUTES.user.english}
            dataQa='english-link'
          />
        </li>
      )}

      {shouldRenderVacanciesItem && (
        <li className={styles.navItem}>
          <SidebarItem
            icon={<IconVacancies className={styles.sidebarIcon} />}
            className={cn({
              [styles.activeSidebarItem]:
                router.pathname.includes(ROUTES.user.vacancies),
            })}
            text={t(`${I18N_CODES.platformSidebar}:vacancies_link`)}
            href={ROUTES.user.vacancies}
            dataQa='vacancies-link'
          />
        </li>
      )}

      {shouldRenderPaymentItem && (
        <li className={styles.navItem}>
          <SidebarItem
            icon={<IconPayments className={styles.sidebarIcon} />}
            className={cn({
              [styles.activeSidebarItem]:
                router.pathname.includes(ROUTES.payments.index),
            })}
            text={t(`${I18N_CODES.platformSidebar}:sidebar.payments_link`)}
            href={paymentRedirectUrl}
            dataQa='payments-link'
          />
        </li>
      )}
    </>
  );
};
