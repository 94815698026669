import { useNextMonthPaymentStatus } from '@/controllers/courseMonthPayments/courseMonthPayments.hooks/useNextMonthPaymentStatus';
import { useCurrenciesRate } from '@/controllers/currenciesRates/currenciesRates.hooks/useCurrenciesRate';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';

interface Options {
  amount: number;
  date: number;
  amountCurrencyId: number;
}

export const useMonthPaymentTotal = (options: Options) => {
  const {
    date,
    amountCurrencyId,
    amount,
  } = options;

  const [paymentStatus] = useNextMonthPaymentStatus();

  const [amountToExtraFeeRate] = useCurrenciesRate({
    variables: {
      date,
      currencyId: amountCurrencyId,
      toCurrencyId: paymentStatus?.extraFeeCurrency?.id || amountCurrencyId,
    },
  });

  const features = useFeatures();

  if (
    !paymentStatus
    || !paymentStatus.shouldPayFee
    || !features.isEnabled(Features.FinesCalculation)
  ) {
    return {
      shouldRenderFee: false,
      extraFee: 0,
      percentageFee: 0,
      total: amount,
    };
  }

  const { extraFeeAmount, feeDays, percentageFeeAmount } = paymentStatus;

  const shouldRenderFee = feeDays > 0;

  const extraFeeRate = +(amountToExtraFeeRate?.exchangeRate || 1).toFixed(2);

  const extraFee = +(extraFeeAmount / extraFeeRate).toFixed(2);

  const percentageFee = +(amount * percentageFeeAmount).toFixed(2);

  const total = shouldRenderFee
    ? +(amount + percentageFee + extraFee).toFixed(2)
    : amount;

  return {
    shouldRenderFee,
    extraFee,
    percentageFee,
    total,
  };
};
