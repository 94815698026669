import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import { cn } from '@/lib/classNames';
import styles from './PaymentsStatisticsPage.module.scss';

const PaymentsStatistics = dynamic<any>(
  () => import('@/components/platform/Payment/PaymentsPage/Pages/StatisticsPage/PaymentsStatistics')
    .then((mod) => mod.PaymentsStatistic),
  { ssr: false },
);

export const PaymentsStatisticsPage: FC = () => (
  <div className="p-40">
    <div className={cn(styles.content, 'grid-container')}>
      <PaymentsStatistics />
    </div>
  </div>
);
