import React, { memo } from 'react';
import { ROUTES } from '@/controllers/router/router.contants';
import { useTranslation } from '@/middleware/i18n';
import { HeaderLogoIcon } from '@/components/landing/LandingHeader/components/HeaderLogoIcon';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import getClientLink from '@/lib/helpers/getClientLink';
import { useShouldShowSinglePageForUsaDomain } from '@/hooks/useShouldShowSinglePageForUsaDomain';
import { AnchorElement } from '@/components/ui/Button/AnchorElement';
import styles from './HeaderLogo.module.scss';

export const HeaderLogo = memo(() => {
  const { i18n } = useTranslation();
  const { isSubscriptionProductDomain, subDomain } = useSubDomainContext();
  const isSinglePage = useShouldShowSinglePageForUsaDomain();

  const knowelyHomeHref = getClientLink(ROUTES.home, {
    domain: subDomain,
    language: i18n.language,
  });

  const homePageHref = isSinglePage
    ? '#'
    : ROUTES.home;

  return (
    <>
      {isSubscriptionProductDomain
        ? (
          <a
            className={styles.logoLink}
            href={knowelyHomeHref}
            aria-label='Home'
          >
            <HeaderLogoIcon />
          </a>
        )
        : (
          <AnchorElement href={homePageHref} className={styles.logoLink}>
            <HeaderLogoIcon />
          </AnchorElement>
        )}
    </>
  );
});
