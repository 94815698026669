/**
 * @deprecated use frontend/src/hooks/useLocaleDateTimeString.ts instead
 */
export const getLocaleDateStringDeprecated = (
  lang: string,
  timestamp: number,
  options?: Intl.DateTimeFormatOptions,
): string => {
  const date = new Date(timestamp);

  return date.toLocaleDateString(lang, options);
};
