import React from 'react';
import { IconEmojiBase } from '@/components/ui/IconEmojies/IconEmojiBase/IconEmojiBase';
import { FCImage } from '@/components/ui/IconEmojies/typedefs';
import { IconImage } from '@/components/ui/Image/IconImage';
import crystalBall from '@/images/generated/icons.emoji.crystalBall.s3Key';

export const IconEmojiCrystalBall: FCImage = ({
  size = 48,
  className,
  alt = 'Crystal ball',
}) => (
  <IconEmojiBase className={className}>
    <IconImage
      imageData={crystalBall}
      width={size}
      height={size}
      alt={alt}
    />
  </IconEmojiBase>
);
