import React, { DetailedHTMLProps, FC, ThHTMLAttributes } from 'react';
import { cn } from '@/lib/classNames';
import tableStyles from '@/components/ui/Table/Table.module.scss';
import { TableWidths, TextAlignValues } from '@/components/ui/Table/Table.typedefs';
import { exists } from '@/lib/helpers/functional';
import styles from './TableHeader.module.scss';

type TableHeaderCellElementProps = DetailedHTMLProps<
  ThHTMLAttributes<HTMLTableCellElement>,
  HTMLTableCellElement
>;

interface CustomProps {
  textAlign?: TextAlignValues;
  width?: TableWidths;
}

export type TableHeaderType = {
  title: string;
  sortable?: boolean;
};

export type TableHeaderProps = TableHeaderCellElementProps & CustomProps;

export const TableHeader: FC<TableHeaderProps> = (props) => {
  const {
    children,
    className,
    textAlign,
    width,
    ...rest
  } = props;

  const classes = cn(
    className,
    styles.tableHeader,
    textAlign
      ? tableStyles[textAlign]
      : tableStyles.left,
    {
      ...(
        exists(width)
          ? {
            [tableStyles[`width-${width}`]]: width,
          }
          : {}
      ),
    },
  );

  return (
    <th {...rest} className={classes} data-field={children}>
      {children}
    </th>
  );
};
